.process-new-section {
    padding: 120px 0 200px 0;
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    min-height: 732px;

	position: relative;

	.banner-background {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}

	.container {
		position: relative;
		z-index: 100;
	}

    &:before {
        content: "";
        background: linear-gradient(283deg, #0086E6 5.56%, #8DD0FF 113.66%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .process-new-content {
        padding-bottom: 40px;
        h2 {
            font-size: 40px;
            line-height: 150%;
            color: #fff;
            font-weight: 700;
            margin: 0;
            text-align: center;
        }
    }

    .process-new-card {
        height: 100%;
        border-radius: 16px;
        border: 2px solid rgba(0, 134, 230, 0.20);
        background: #fff;
        box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.04);
        padding: 24px;
        position: relative;
        z-index: 9;
        &:before {
            content: "";
            background: #fff;
            width: 18px;
            height: 18px;
            position: absolute;
            bottom: -10px;
            left: 0;
            right: 0;
            margin: auto;
            border-radius: 100px;
            border: 1px solid #d6eeff;
        }
        .process-heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 16px;
            h3 {
                margin: 0;
            }

            .next-project-left-content {
                position: relative;
                margin-right: 22px;

                .next-project-profile {
                    width: 72px;
                    height: 72px;
                    border-radius: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 12px;
                    border: 2px solid rgba(0, 134, 230, 0.07);
                    background: #fff;
                    position: relative;
                    z-index: 9;

                    img {
                        width: 40px;
                        height: 40px;
                    }
                }
                span {
                    width: 72px;
                    height: 72px;
                    border-radius: 12px;
                    border: 1px solid #fff;
                    opacity: 0.1;
                    background: linear-gradient(283deg, #0086E6 5.56%, #8DD0FF 113.66%);
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    transition: 0.3s;
                }
            }
        }
        &:hover {
            .next-project-left-content {
                span {
                    opacity: 0.1;
                    top: 10px;
                    left: 10px;
                }
            }
        }
        .process-body {
            display: flex;
            flex-direction: column;
            h3 {
                font-size: 20px;
                line-height: 150%;
                font-weight: 600;
                color: #040405;
                margin: 0;
                margin-bottom: 16px;
            }
            p {
                font-size: 16px;
                line-height: 150%;
                font-weight: 400;
                color: #040405;
                margin: 0;
            }
        }

        .btn-steps {
            position: absolute;
            bottom: -80px;
            left: 0;
            right: 0;
            margin: 0 auto;
            border-radius: 8px;
            border: 2px solid #fff;
            background: #fff;
            box-shadow:
                0px 7.467px 5.973px 0px rgba(0, 0, 0, 0.03),
                0px 35.296px 23.35px 0px rgba(0, 0, 0, 0.04),
                0px 91.635px 73.308px 0px rgba(0, 0, 0, 0.04);
            max-width: 80px;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            text-transform: none !important;
            span {
                background: rgba(85, 92, 96, 1);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            &:before {
                content: "";
                height: 100%;
                width: 1px;
                border: 1px dashed rgba(255, 255, 255, 0.4);
                position: absolute;
                top: -34px;
                left: 0;
                right: 0;
                margin: auto;
                z-index: -1;
            }
        }
        .remove-border {
            &:after {
                display: none;
            }
        }
    }
    .process-new-card-green {
        .process-heading {
            .next-project-left-content {
                .next-project-profile {
                    border: 2px solid rgba(138, 188, 0, 0.07);
                }
                span {
                    background: linear-gradient(283deg, #8ABC00 5.56%, #F1F8DA 113.66%);
                }
            }
        }
    }
    .card-border-line {
        position: relative;
        z-index: 9;
        &:after {
            content: "";
            height: 1px;
            width: 100%;
            border: 1px dashed rgba(255, 255, 255, 0.4);
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            bottom: -60px;
            max-width: 61%;
            z-index: -1;
        }
    }
}

// technology-process-new section
.technology-process-new-section {
    .card-border-line {
        &::after {
            max-width: 70%;
        }
    }
}

// new code

.theme-dark {
    .process-new-section {
        position: relative;
        // background-image: url("../../../public/images/home/process-banner.png"), cover, no-repeat;
    }

    .container {
        position: relative;
        z-index: 99;
    }

    .process-new-section::before {
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.3;
        background: linear-gradient(
            122deg,
            #0091da -11.62%,
            rgba(0, 145, 218, 0.88) 8.53%,
            rgba(69, 168, 104, 0) 69.21%,
            #84bd00 100%
        );
        display: block;
        z-index: 2;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1025px) {
    .process-new-section {
        min-height: 580px;
        padding: 80px 0 160px 0 !important;
    }
    .container {
        max-width: 1000px !important;
    }
    .process-new-section .process-new-card {
        padding: 16px;
    }
    .process-new-section .process-new-card .process-body h3 {
        font-size: 16px;
        margin-bottom: 8px;
    }
    .process-new-section .process-new-card .process-body p {
        font-size: 14px;
    }
    .process-new-section .process-new-card .process-heading .next-project-left-content .next-project-profile {
        width: 48px;
        height: 48px;
        border-radius: 8px;
    }
    .process-new-section .process-new-card .process-heading .next-project-left-content span {
        width: 48px;
        height: 48px;
        border-radius: 8px;
    }
    .process-new-section .process-new-card .process-heading .next-project-left-content .next-project-profile img {
        width: 24px;
        height: 24px;
    }
}

@media (max-width: 1024px) {
    .process-new-section {
        padding: 60px 0 150px 0;
        min-height: auto;
    }
    .process-new-section .process-new-content {
        padding-bottom: 32px;
    }
    .process-new-section .process-new-content h2 {
        font-size: 32px;
    }
    .process-new-section .process-new-card {
        padding: 16px;
    }
    .process-new-section .process-new-card .process-body h3 {
        font-size: 16px;
        margin-bottom: 8px;
    }
    .process-new-section .process-new-card .process-body p {
        font-size: 14px;
    }
    .process-new-section .process-new-card .process-heading .next-project-left-content .next-project-profile {
        width: 48px;
        height: 48px;
    }
    .process-new-section .process-new-card .process-heading .next-project-left-content span {
        width: 48px;
        height: 48px;
    }
    .process-new-section .process-new-card .process-heading .next-project-left-content .next-project-profile img {
        width: 24px;
        height: 24px;
    }
}

@media (max-width: 992px) {
    .process-new-section {
        padding: 50px 0 120px 0;
        .process-new-card {
            padding: 16px;

            .btn-steps {
                font-size: 14px;
                padding: 6px 8px;
                max-width: 80px;
            }
            .process-heading {
                h3 {
                    img {
                        width: 34px;
                    }
                }
                .next-project-left-content {
                    span {
                        width: 48px;
                        height: 48px;
                        border-radius: 8px;
                    }
                    .next-project-profile {
                        width: 48px;
                        height: 48px;
                        border-radius: 8px;
                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }
            .process-body {
                h3 {
                    font-size: 16px;
                    margin-bottom: 7px;
                }
                p {
                    font-size: 14px;
                }
            }
        }

        .process-new-content {
            padding-bottom: 20px;
            h2 {
                font-size: 24px;
                line-height: 29px;
            }
        }
    }
    .process-new-section .card-border-line {
        --bs-gutter-x: 12px;
    }
}

@media (max-width: 767px) {
    .process-new-section {
        padding: 40px 0 10px 0;
        .process-new-card {
            height: unset;
            margin-bottom: 100px;
            .btn-steps {
                bottom: -65px;
            }
        }
        .card-border-line {
            &:after {
                display: none;
            }
        }
    }
}
